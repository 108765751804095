import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Callback = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const toast = useRef(null);

    // Now you can access individual query parameters
    const status = queryParams.get('status');
    const token = queryParams.get('token');
    const employeeId = queryParams.get('employeeId');

    useEffect(() => {
        if (status === 'success' && token !== null && token !== undefined) {
            localStorage.setItem('_token', token);
            setTimeout(() => {
                navigate('/');
            }, 1000);
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Either Employee ID or Date of Birth (DOB) is incorrect',
                life: 3000
            });
        }
    }, [token, status]);

    useEffect(() => {
        if (employeeId) {
            localStorage.setItem('employeeId', employeeId);
        }
    }, [employeeId]);

    return null;
};

export default Callback;
